import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '@/views/index/Index.vue';
import Main from '@/views/main/Main.vue';
import Other from '@/views/other/Other.vue';
import Login from '@/views/index/Login.vue';
import Task from '@/views/main/Task.vue';

Vue.use(VueRouter)

const globalRoutes = [];

const indexRoutes = {
  path: '/index',
  component: Index,
  name: 'index',
  redirect: { name: 'login' },
  children: [
    { path: '/login', name: 'login', component: Login },
    { path: '/register', name: 'register', component: () => import('@/views/index/SetPassword.vue') },
    { path: '/reset', name: 'reset', component: () => import('@/views/index/SetPassword.vue') },
    { path: '/register-mobile', name: 'register-mobile', component: () => import('@/views/index/CheckMobile.vue') },
    { path: '/reset-mobile', name: 'reset-mobile', component: () => import('@/views/index/CheckMobile.vue') },
  ],
};

const mainRoutes = {
  path: '/',
  component: Main,
  name: 'main',
  redirect: { name: 'task' },
  children: [
    { path: '/task', name: 'task', component: Task },
    { path: '/order', name: 'order', component: () => import('@/views/main/Order.vue') },
    { path: '/account', name: 'account', component: () => import('@/views/main/Account.vue') },
    { path: '/my', name: 'my', component: () => import('@/views/main/My.vue') },
    { path: '/tools', name: 'tools', component: () => import('@/views/main/Tools.vue') },
  ],
  beforeEnter(to, from, next) {
    let token = window.localStorage.getItem('token');
    if (token === null || token.length !== 32) {
      window.localStorage.removeItem('token');
      next({ name: 'login' });
    }
    next();
  },
};

const otherRoutes = {
  path: '/other',
  component: Other,
  name: 'other',
  children: [
    { path: '/user-info', name: 'user-info', component: () => import('@/views/other/son/UserInfo.vue') },
    { path: '/amount', name: 'amount', component: () => import('@/views/other/son/Amount.vue') },
    { path: '/script-config', name: 'script-config', component: () => import('@/views/other/son/ScriptConfig.vue') },
    { path: '/recharge', name: 'recharge', component: () => import('@/views/other/son/Recharge.vue') },
    { path: '/free-account', name: 'free-account', component: () => import('@/views/other/son/FreeAccount.vue') },
    { path: '/using-price', name: 'using-price', component: () => import('@/views/other/son/UsingPrice.vue') },
    { path: '/ph-price', name: 'ph-price', component: () => import('@/views/other/son/PhPrice.vue') },
    { path: '/server-goods', name: 'server-goods', component: () => import('@/views/other/son/ServerGoods.vue') },
    { path: '/role-goods', name: 'role-goods', component: () => import('@/views/other/son/RoleGoods.vue') },
    { path: '/invite-record', name: 'invite-record', component: () => import('@/views/other/son/InviteRecord.vue') },
    { path: '/game-server', name: 'game-server', component: () => import('@/components/GameServer.vue') },
    { path: '/st-hot', name: 'st-hot', component: () => import('@/views/other/son/StHot.vue') },
    { path: '/device-grant', name: 'device-grant', component: () => import('@/views/other/son/DeviceGrant.vue') },
    { path: '/mobile-device', name: 'mobile-device', component: () => import('@/views/other/son/MobileDevice.vue') },
    { path: '/data', name: 'data', component: () => import('@/views/other/son/Data.vue') },
    { path: '/ph-needs', name: 'ph-needs', component: () => import('@/views/other/son/PhNeeds.vue') },
    { path: '/public-monster', name: 'public-monster', component: () => import('@/views/other/son/PublicMonster.vue') },
  ],
  beforeEnter(to, from, next) {
    let token = window.localStorage.getItem('token');
    if (token === null || token.length !== 32) {
      window.localStorage.removeItem('token');
      next({ name: 'login' });
    }
    next();
  },
};

const otherScript = { path: '/other-script', name: 'other-script', component: () => import('@/views/other/son/OtherScript.vue') }

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: globalRoutes.concat(mainRoutes, indexRoutes, otherRoutes, otherScript)
})

export default router
