import axios from 'axios';
import router from '@/router';
import { Toast } from 'vant';

const http = axios.create({
  timeout: 1000 * 30,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
  },
});

//设置baseUrl
if (process.env.NODE_ENV === 'production') {
  http.defaults.baseURL = 'http://119.45.127.198:543/api';
} else {
  http.defaults.baseURL = 'http://127.0.0.1:8080/api';
  http.defaults.baseURL = 'http://119.45.127.198:543/api';
}

//请求拦截
http.interceptors.request.use((config) => {
  let reg = /\?/
  if (reg.test(config.url)) {
    config.url = config.url + "&t=" + new Date().getTime();
  } else {
    config.url = config.url + "?t=" + new Date().getTime();
  }
  config.headers.token = window.localStorage.getItem('token');
  return config;
});

//响应拦截
http.interceptors.response.use((response) => {
  if (response.data.code !== 0) {
    Toast(response.data.msg);
  }
  if (response.data.code === 10002) {
    Toast('请重新登录');
    window.localStorage.removeItem('token');
    if (router.app._route.name !== 'login') {
      router.push({ name: 'login' });
    }
  }
  return response;
});

export default http;
