<template>
  <div class="main">
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
    <van-dialog v-model="showDialog" title="信息" @closed="dialogCloseHandle()">
      <div v-if="loadingData" class="loading">
        <van-loading />
      </div>
      <div class="log" :style="'height: ' + (clientHeight - 300) + 'px'">
        <div v-for="item in dataList" :key="item.id">
          {{ `${item.saveTime}:${item.message}` }}
        </div>
      </div>
    </van-dialog>
    <van-tabbar v-model="active" route @change="onChange">
      <van-tabbar-item icon="flag-o" to="/task">任务</van-tabbar-item>
      <van-tabbar-item icon="diamond-o" to="/account">账号</van-tabbar-item>
      <van-tabbar-item icon="orders-o" to="/order">订单</van-tabbar-item>
      <van-tabbar-item icon="apps-o" to="/tools">工具</van-tabbar-item>
      <van-tabbar-item icon="contact" to="/my">我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<script>
export default {
  data() {
    return {
      active: 0,
      showDialog: false,
      loadingData: false,
      dataList: [],
    };
  },

  created() {
    this.$store.dispatch("updateServerList");
    this.$store.dispatch("updateConfigList");
    this.$store.dispatch("updateUser");
  },

  computed: {
    clientHeight: {
      get() {
        return this.$store.state.clientHeight;
      },
    },
    showLog: {
      get() {
        return this.$store.state.showLog;
      },
      set(val) {
        this.$store.commit("setShowLog", val);
      },
    },
    logId: {
      get() {
        return this.$store.state.logId;
      },
      set(val) {
        this.$store.commit("setLogId", val);
      },
    },
  },

  methods: {
    //获取用户消息
    getUserMessage() {
      this.$http({
        url: "/user-message/list",
        method: "get",
      }).then((value) => {
        const res = value.data;
        if (res && res.data && res.data.length > 0) {
          this.dataList = res.data;
          this.showDialog = true;
        }
      });
    },
    dialogCloseHandle() {
      this.showLog = false;
      if (this.dataList.length > 0 && !this.dataList[0].taskId) {
        let ids = this.dataList.map((item) => item.id);
        this.$http({
          url: "/user-message/delete",
          method: "post",
          data: ids,
        });
      }
    },
    onChange(index) {
      this.active = index;
      this.getUserMessage();
    },
    //展示日志信息
    async showLogMessage() {
      this.dataList = [];
      this.loadingData = true;
      this.showDialog = true;
      let { data: res } = await this.$http({
        url: "/script-log/page",
        method: "get",
        params: {
          page: 1,
          limit: 5000,
          taskId: this.logId,
        },
      });
      if (res.code === 0) {
        this.dataList = res.page.list;
        this.loadingData = false;
      }
    },
  },
  watch: {
    showLog(newValue, oldValue) {
      if (newValue) {
        this.showLogMessage();
      }
    },
  },
};
</script>
<style lang="less" scoped>
.main {
  height: 100%;
  overflow-y: hidden;

  .van-dialog {
    .loading {
      width: 100%;
      height: 100px;
      position: relative;

      .van-loading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .log {
      padding: 10px 5px;
      height: 100%;
      overflow-y: scroll;

      div {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
}
</style>